const state = {
  schema: [
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'ScoreMaster',
      alternateName: 'Score Master',
      logo: 'https://www.scoremaster.com/ScoreMaster.png',
      url: 'https://www.scoremaster.com',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Irvine, CA USA',
        postalCode: '92612',
        streetAddress: '2983 Michelson Dr. ',
      },
      email: 'info@consumerdirect.com',
      description:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
      telephone: '+18773723895',
    },
  ], //schema state for pages
  defaultSchema: [
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'ScoreMaster',
      alternateName: 'Score Master',
      logo: 'https://www.scoremaster.com/ScoreMaster.png',
      url: 'https://www.scoremaster.com',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Irvine, CA USA',
        postalCode: '92612',
        streetAddress: '2983 Michelson Dr.',
      },
      email: 'info@consumerdirect.com',
      description:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
      telephone: '+18773723895',
    },
  ], //default schema state used to reset schema when user leaves pages
}

export default state
